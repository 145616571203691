// src/AuthService.js
const  ip ='192.168.20.58';



class UserService {
  async user(name,lastName,email,contact, password) {
 
      const usPass = btoa(password);

      const response = await fetch('http://'+ip+':5000/api/endpoint', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': 'tu_api_key_aqui',
          'Authorization': 'A',
          'BASIC-KEY': '',
          'X-PATH': '/core/users/post',
          'CLIENT-INFO': '',
        },
        body: JSON.stringify({
          name: name,
          lastName: lastName,
          email: email,
          contact: contact,

          pass: usPass,
        }),
      });

      const result = await response.json();
      if(result.validated===false){
        return result;
      }
      sessionStorage.setItem('X-API-KEY', result.data[0].token);
      sessionStorage.setItem('userName', result.data[0].userName);
      sessionStorage.setItem('name', result.data[0].name);
      sessionStorage.setItem('lastName', result.data[0].lastName);
      sessionStorage.setItem('email', result.data[0].email);
      sessionStorage.setItem('contact', result.data[0].contact);
      sessionStorage.setItem('userId', result.data[0].userId);
      sessionStorage.setItem('sessionId', result.data[0].sessionId);
      sessionStorage.setItem('frontId', result.data[0].frontId);
      return result;
  
  }
  async userGet(filter,param,value,limit, offset) {
    const requestData = {
      filter,
      param,
      value,
      limit,
      offset,
    };

    try {
      const response = await fetch('http://'+ip+':5000/api/endpoint/get/' + JSON.stringify(requestData), {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
          'X-APIKEY': sessionStorage.getItem('X-API-KEY'),
          'X-PATH': '/core/users/get/',
          'X-FRONT': sessionStorage.getItem('frontId'),
          'userId': sessionStorage.getItem('userId'),
          'sessionId': sessionStorage.getItem('sessionId')
        },
      });

     // const result = await response.json();
    //  setResponseMessage(result.message || 'Datos recibidos correctamente.');

      // Agregamos un console.log para inspeccionar la respuesta completa
     // console.log('Respuesta completa del API:', response);

        return response;
      
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
      //setResponseMessage('Error al hacer la solicitud.');
    }
  
  }
  async logout() {
 

      const response = await fetch('http://'+ip+':5000/core/auth/postOut', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': sessionStorage.getItem('X-API-KEY'),
          'userId': sessionStorage.getItem('userId'),
          'sessionId': sessionStorage.getItem('sessionId')
        },
        body: JSON.stringify({
          sessionId: sessionStorage.getItem('sessionId')
        }),
      });

      const result = await response.json();
      if(result.validated===false){
        return result;
      }
      sessionStorage.clear();
      return result;
  
  }
}

export default new UserService();
