import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../classes/AuthService';


function ClientValidation() {
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await AuthService.clientValidation(password);

      if (result.validated === true) {
       

        navigate('/Login');
      } else {
        navigate('/ClientValidation');
        setErrorMessage(result.response);
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

 
  return (
    <div style={styles.app}>
      <h1 style={styles.heading}>Validación de cuenta</h1>
      <section  style={styles.form} class="box is-rounded">
       
      
      <div class="field">
  <label class="label">Escriba el código recibido en su correo</label>
  <div class="control">
  <input
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={styles.input}
          />
       
  </div>
  <p class="help">This is a help text</p>
  <button class="button is-rounded fas fa-check" onClick={handleLoginSubmit}></button>
</div>
</section>
    </div>
  );
}
const styles = {
  app: {
    fontFamily: 'Arial, sans-serif',
    padding: '250px',
    backgroundColor: '#f4f7fc',
    color: '#333',
  },
  heading: {
    color: '#3f51b5',
    marginBottom: '20px',
    fontSize: '24px',
    textAlign: 'center',
  },
  form: {
    maxWidth: '600px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    fontSize: '16px',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    boxSizing: 'border-box',
  },
  button: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
  },
  errorMessage: {
    color: 'red',
    textAlign: 'center',
    marginTop: '10px',
  },
};
export default ClientValidation;
