import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../classes/AuthService';
import Header from './Header';

function MainApp() {
  
  const navigate = useNavigate();
 
  useEffect(() => {
    if(!sessionStorage.getItem('userId') || !sessionStorage.getItem('frontId') || !sessionStorage.getItem('sessionId') || !sessionStorage.getItem('X-API-KEY')){
      navigate('/Login');
    }
    console.log('MainApp component is being rendered');
  }, []);

  // Estados para el formulario de creación de cliente
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientKey, setClientKey] = useState('');
  const [clientContact, setClientContact] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
 
  // Estados para la solicitud GET
  const [filter, setFilter] = useState('');
  const [param, setParam] = useState('');
  const [value, setValue] = useState('');
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [clientsData, setClientsData] = useState([]);

  // Función para manejar el envío del formulario de creación de cliente

 const handleLogOut = async (e) => {
  
  e.preventDefault();
 
    try {
      const result = await AuthService.logout();

      if (result.validated === true) {
       
console.log('validado');
        navigate('/Login');
      } else {
        setErrorMessage(result);
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  }


 const handleLogClients = async (e) => {
  
  e.preventDefault();
 
    
        navigate('/Clients');
     
    
  }

 const handleLogUSers = async (e) => {
  
  e.preventDefault();
 
    
        navigate('/Users');
     
    
  }
  // Función para manejar el envío de la solicitud GET
  

  return (
    <div className="App" style={styles.app}>
       {/* Navbar de Bulma */}
     
       <Header
        currentPage={1}
        totalPages={1}
        onPageChange={1}
      />

      <h1 style={styles.heading}>esta es la pagina de inicio principal donde se muestras dashboards</h1>
     

      {clientsData.length > 0 && (
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.tableHeader}>Alias</th>
              <th style={styles.tableHeader}>ID</th>
              <th style={styles.tableHeader}>Nombre</th>
              <th style={styles.tableHeader}>Email</th>
            </tr>
          </thead>
          <tbody>
            {clientsData.map((client) => (
              <tr key={client.id}>
                <td style={styles.tableData}>{client.alias}</td>
                <td style={styles.tableData}>{client.id}</td>
                <td style={styles.tableData}>{client.name}</td>
                <td style={styles.tableData}>{client.info.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

const styles = {
  app: {
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    backgroundColor: '#f4f7fc',
    color: '#333',
  },
  heading: {
    color: '#3f51b5',
    marginBottom: '20px',
    fontSize: '24px',
    textAlign: 'center',
  },
  form: {
    maxWidth: '600px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    fontSize: '16px',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    boxSizing: 'border-box',
  },
  button: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
  },
  responseMessage: {
    textAlign: 'center',
    color: '#4caf50',
    fontSize: '18px',
    marginTop: '20px',
  },
  table: {
    marginTop: '30px',
    width: '100%',
    borderCollapse: 'collapse',
    backgroundColor: '#fff',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  },
  tableHeader: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    padding: '10px',
    textAlign: 'left',
  },
  tableData: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
  }
};

export default MainApp;
