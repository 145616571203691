// App.js
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import MainApp from './components/MainApp';
import CreateUser from './components/CreateUser';
import Clients from './components/Clients';
import Users from './components/Users';
import ClientValidation from './components/ClientValidation';


function App() {
  return (
    <Routes>
      {/* Ruta para el login */}
      <Route path="/login" element={<Login />} />
      
      {/* Ruta para el MainApp después del inicio de sesión */}
      <Route path="/MainApp" element={<MainApp />} />
      <Route path="/CreateUser" element={<CreateUser />} />
      <Route path="/Clients" element={<Clients />} />
      <Route path="/Users" element={<Users />} />
      <Route path="/ClientValidation" element={<ClientValidation />} />
      
      {/* Redirecciona cualquier ruta desconocida al login */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default App;
 