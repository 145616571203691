import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from './Pagination';
import Header from './Header';
import ClientService from '../classes/ClientService';
import AuthService from '../classes/AuthService';

function Clients() {
  const navigate = useNavigate();
 
  useEffect(() => {
    if(!sessionStorage.getItem('userId') || !sessionStorage.getItem('frontId') || !sessionStorage.getItem('sessionId') || !sessionStorage.getItem('X-API-KEY')){
      navigate('/Login');
    }
    
    handlePageChangeF(1,0);
    console.log('MainApp component is being rendered');
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  var [registId, setRegistId] = useState(0);
  //var totalPages = 2; // Número total de páginas
  const [totalPages, setCTotalPages] = useState(2);
  // Estados para el formulario de creación de cliente
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientKey, setClientKey] = useState('');
  const [clientContact, setClientContact] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
 
  // Estados para la solicitud GET
  const [filter, setFilter] = useState('');
  const [param, setParam] = useState('');
  const [value, setValue] = useState('');
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [clientsData, setClientsData] = useState([]);
var registersqty=0;
var paginationMessage="";

  // Función para manejar el envío del formulario de creación de cliente
  const handleClientSubmit = async (e) => {
    e.preventDefault();

    const data = {
      clientName,
      clientEmail,
      clientKey,
      clientContact,
      qId: '14574547',
    };

    try {
      const response = await fetch('http://192.168.20.58:5000/api/endpoint', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': 'tu_api_key_aqui',
          'Authorization': 'A',
          'BASIC-KEY': 'tu_valor_basico_aqui',
          'X-PATH': '/core/clients/post',
          'X-METHOD': 'POST',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if(result.response=="IAM ISSUE" && result.validated==false){
         
      return AuthService.sessionExpired();
    }
    } catch (error) {
      console.error('Error en el envío:', error);
    }
  };

  // Función para manejar el envío de la solicitud GET
  const handleGetSubmit = async (e, limit, offset) => {
    if (e && e.preventDefault) {
      e.preventDefault(); // Evitar el comportamiento predeterminado del formulario
    }
 
  
  
    try {
      
      const response = await ClientService.clientGet(filter, param,value,10,0);
  
     
      const result = await response.json();
      if(result.response=="IAM ISSUE" && result.validated==false){
        return  AuthService.sessionExpired();

    }
      console.log('Respuesta completa del API:', result);
      paginationMessage=result.message;
      
      setResponseMessage(result.message || 'Datos recibidos correctamente.');
      setClientsData(result.data);
     
      
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
      setResponseMessage('Error al hacer la solicitud.');
    }
  };
  
  const handlePageChange = async (page,offset) => {
    setCurrentPage(page);
    page = Number(page);
    offset = 0;
  
    if (page > 1) {
      offset = (page - 1) * 10;
    }
    if(filter==""){
      setFilter('all');
    }
    if(param==""){
      setParam('all');
    }
    if(value==""){
      setValue('all');
    }
    console.log(`Cambió a la página: ${page}`);
    const requestData = {
      filter,
      param,
      value,
      limit: 10,
      offset,
    };
  
    try {
     
   
      const response = await ClientService.clientGet(filter, param,value,limit,offset);
  
     

      const result = await response.json();
      if(result.response=="IAM ISSUE" && result.validated==false){
        return  AuthService.sessionExpired();

        
    }
      console.log('Respuesta completa del API:', result);
      paginationMessage=result.message;
      setCTotalPages(Math.ceil(paginationMessage / 10));
      console.log(totalPages);
      // Actualiza los datos y el mensaje de respuesta
      setClientsData(result.data || []);
      setResponseMessage(result.message || 'Datos recibidos correctamente.');
   
      // Ahora accedemos a destination_response.data para obtener los datos de clientes
      if (result?.data) {
        setClientsData(result.data);
      } else {
        setClientsData([]); // Si no hay datos o el formato no es el esperado
        console.log('No se encontraron datos de clientes o el formato no es el esperado');
      }
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
      setResponseMessage('Error al hacer la solicitud.');
    }
   
   
   
    
  };
  

  async function handlePageChangeF(page,offset)  {
    setCurrentPage(page);
    page = Number(page);
    offset = 0;
  
    if (page > 1) {
      offset = (page - 1) * 10;
    }
    setFilter('all');
   
   
    setParam('all');

 
    setValue('all');
    console.log(`Cambió a la página: ${page}`);
    const requestData = {
      filter,
      param,
      value,
      limit: 10,
      offset,
    };
  
    try {
     
   
      const response = await ClientService.clientGet('all', 'all','all',10,0);
  
     

      const result = await response.json();
      if(result.response=="IAM ISSUE" && result.validated==false){
        return  AuthService.sessionExpired();

        
    }

      console.log('Respuesta completa del API:', result);
      paginationMessage=result.message;
      setCTotalPages(Math.ceil(paginationMessage / 10));
      console.log(totalPages);
      // Actualiza los datos y el mensaje de respuesta
      setClientsData(result.data || []);
      setResponseMessage(result.message || 'Datos recibidos correctamente.');
   
      // Ahora accedemos a destination_response.data para obtener los datos de clientes
      if (result?.data) {
        setClientsData(result.data);
      } else {
        setClientsData([]); // Si no hay datos o el formato no es el esperado
        console.log('No se encontraron datos de clientes o el formato no es el esperado');
      }
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
      setResponseMessage('Error al hacer la solicitud.');
    }
   
   
   
    
  };
  return (
    
    <div className="App" style={styles.app}>
       {/* Navbar de Bulma */}
       <Header
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />




      <h1 style={styles.heading}>Formulario de Creación de Cliente</h1>
      <form onSubmit={handleClientSubmit} style={styles.form}>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Nombre del Cliente:</label>
          <input
            type="text"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Correo Electrónico:</label>
          <input
            type="email"
            value={clientEmail}
            onChange={(e) => setClientEmail(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Clave del Cliente:</label>
          <input
            type="text"
            value={clientKey}
            onChange={(e) => setClientKey(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Contacto del Cliente:</label>
          <input
            type="text"
            value={clientContact}
            onChange={(e) => setClientContact(e.target.value)}
            style={styles.input}
          />
        </div>
        <button type="submit" style={styles.button}>Enviar</button>
      </form>

      <h2 style={styles.responseMessage}>{responseMessage}</h2>

      <h1 style={styles.heading}>Formulario de Búsqueda de Clientes</h1>
      <form onSubmit={(e) => handleGetSubmit(e, 5, 0)} style={styles.form}>

        <div style={styles.inputGroup}>
          <label style={styles.label}>Filtro:</label>
          <input
            type="text"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Parámetro:</label>
          <input
            type="text"
            value={param}
            onChange={(e) => setParam(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Valor:</label>
          <input
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={styles.input}
          />
        </div>
       
       
        <button type="submit" style={styles.button}>Buscar</button>
      </form>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      {clientsData.length > 0 && (
        <table class="table">
          <thead>
            <tr>
            <th >Lock/Active/Validated</th>
           
              <th >Alias</th>
              <th >ID</th>
              <th >Nombre</th>
              <th >Email</th>
            </tr>
          </thead>
          <tbody>
  {clientsData.map((client) => (
   registersqty=registersqty+1,
    <tr key={client.id}>
        <td >{client.info.status=== true ? <i class="tag is-rounded is-medium is-success fas" title='DETAILS'></i>:<i class="tag is-rounded is-medium is-danger fas" title='DETAILS'></i>}  {client.info.isActive=== true ? <i class="tag is-rounded is-medium is-success fas" title='DETAILS'></i>:<i class="tag is-rounded is-medium is-warning fas" title='DETAILS'></i>}     {client.info.isValidated=== true ? <i class="tag is-rounded is-medium is-success fas" title='DETAILS'></i>:<i class="tag is-rounded is-medium is-warning fas" title='DETAILS'></i>}</td>

     
      <td style={styles.tableData}>{client.alias || 'N/A'}</td>
      <td style={styles.tableData}>{client.id || 'N/A'}</td>
      <td style={styles.tableData}>{client.name || 'N/A'}</td>
      <td style={styles.tableData}>{client.info?.email || 'N/A'}</td>
    </tr>
    
  ))}
</tbody>

        </table>
        
      )
      }
       <div>
  
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
      {/* <nav class="pagination" role="navigation" aria-label="pagination">
  <a class="pagination-previous is-disabled" title="This is the first page"
    >Previous</a
  >
  <a href="#" className="pagination-next" data-value={paginationMessage}>{paginationMessage}</a>
  <ul class="pagination-list">
    <li>
      <a
        class="pagination-link is-current"
        aria-label="Page 1"
        aria-current="page"
        onClick={(e) => handleGetSubmit(e, 10, 0)}>1</a
      >
    </li>
    <li>
      <a href="#" class="pagination-link is-current" aria-label="Goto page 2" onClick={(e) => handleGetSubmit(e, 10, 10)}>2</a>
    </li>
    <li>
      <a href="#" class="pagination-link is-current" aria-label="Goto page 3" onClick={(e) => handleGetSubmit(e, 10, 20)}>3</a>
    </li>
    <li>
      <a href="#" class="pagination-link is-current" aria-label="Goto page 3" onClick={(e) => handleGetSubmit(e, 10, 30)}>4</a>
    </li>
  </ul>
</nav>*/}
    </div> 
    
  );
  
}

const styles = {
  app: {
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    backgroundColor: '#f4f7fc',
    color: '#333',
  },
  heading: {
    color: '#3f51b5',
    marginBottom: '20px',
    fontSize: '24px',
    textAlign: 'center',
  },
  form: {
    maxWidth: '600px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    fontSize: '16px',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    boxSizing: 'border-box',
  },
  button: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
  },
  responseMessage: {
    textAlign: 'center',
    color: '#4caf50',
    fontSize: '18px',
    marginTop: '20px',
  },
  table: {
    marginTop: '30px',
    width: '100%',
    borderCollapse: 'collapse',
    backgroundColor: '#fff',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  },
  tableHeader: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    padding: '10px',
    textAlign: 'left',
  },
  tableData: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
  }
};

export default Clients;
