import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserService from '../classes/UserService';
import AuthService from '../classes/AuthService';
import Header from './Header';
import Pagination from './Pagination';
import Swal from 'sweetalert2';
  function Users() {
  const navigate = useNavigate();
 
  useEffect(() => {
    if(!sessionStorage.getItem('userId') || !sessionStorage.getItem('frontId') || !sessionStorage.getItem('sessionId') || !sessionStorage.getItem('X-API-KEY')){
      navigate('/Login');
    }
    handlePageChangeF(1,0);
    console.log('MainApp component is being rendered');
  }, []);
  const [currentPage, setCurrentPage] = useState(1);

  //var totalPages = 2; // Número total de páginas
  const [totalPages, setCTotalPages] = useState(2);
  // Estados para el formulario de creación de cliente
 
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
 
  // Estados para la solicitud GET
  const [filter, setFilter] = useState('');
  const [param, setParam] = useState('');
  const [value, setValue] = useState('');
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [clientsData, setClientsData] = useState([]);

  // Función para manejar el envío del formulario de creación de cliente
  var registersqty=0;
  var paginationMessage="";
  

  // Función para manejar el envío de la solicitud GET
  const handleGetSubmit = async (e) => {
    e.preventDefault();
     const requestData = {
      filter,
      param,
      value,
      limit,
      offset,
    };

    try {
      const response = await UserService.userGet(filter, param,value,limit,offset);
  
     

      const result = await response.json();
      if(result.response=="IAM ISSUE" && result.validated===false){

        await AuthService.logout();
 
    
        
 console.log('validado');
       return  navigate('/Login');
      
       
   }
      setResponseMessage(result.message || 'Datos recibidos correctamente.');

      // Agregamos un console.log para inspeccionar la respuesta completa
      console.log('Respuesta completa del API:', result);

      // Ahora accedemos a destination_response.data para obtener los datos de clientes
      if (result?.data) {
        setClientsData(result.data);
      } else {
        setClientsData([]); // Si no hay datos o el formato no es el esperado
        console.log('No se encontraron datos de clientes o el formato no es el esperado');
      }
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
      setResponseMessage('Error al hacer la solicitud.');
    }
  };
  const handlePageChange = async (page,offset) => {
    setCurrentPage(page);
    page = Number(page);
    offset = 0;
  
    if (page > 1) {
      offset = (page - 1) * 10;
    }
    if(filter==""){
      setFilter('all');
    }
    if(param==""){
      setParam('all');
    }
    if(value==""){
      setValue('all');
    }
    console.log(`Cambió a la página: ${page}`);
    const requestData = {
      filter,
      param,
      value,
      limit: 10,
      offset,
    };
  
    try {
     
   
      const response = await UserService.userGet(filter, param,value,limit,offset);
  
     

      const result = await response.json();
      if(result.response=="IAM ISSUE" && result.validated==false){

        await AuthService.logout();
 
    
        
 console.log('validado');
       return  navigate('/Login');
      
       
   }
      console.log('Respuesta completa del API:', result);
      paginationMessage=result.message;
      setCTotalPages(Math.ceil(paginationMessage / 10));
      console.log(totalPages);
      // Actualiza los datos y el mensaje de respuesta
      setClientsData(result.data || []);
      setResponseMessage(result.message || 'Datos recibidos correctamente.');
   
      // Ahora accedemos a destination_response.data para obtener los datos de clientes
      if (result?.data) {
        setClientsData(result.data);
      } else {
        setClientsData([]); // Si no hay datos o el formato no es el esperado
        console.log('No se encontraron datos de clientes o el formato no es el esperado');
      }
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
      setResponseMessage('Error al hacer la solicitud.');
    }
   
   
   
    
  };
  async function handlePageChangeF(page,offset){
    setCurrentPage(page);
    page = Number(page);
    offset = 0;
  
    if (page > 1) {
      offset = (page - 1) * 10;
    }
   
      setFilter('all');
   
   
      setParam('all');
  
   
      setValue('all');
    
    console.log(`Cambió a la página: ${page}`);
    const requestData = {
      filter,
      param,
      value,
      limit: 10,
      offset,
    };
  
    try {
     
   
      const response = await UserService.userGet('all', 'all','all',10,0);
  
     

      const result = await response.json();
      if(result.response=="IAM ISSUE" && result.validated==false){

        await AuthService.logout();
 
    
        
 console.log('validado');
       return  navigate('/Login');
      
       
   }
      console.log('Respuesta completa del API:', result);
      paginationMessage=result.message;
      setCTotalPages(Math.ceil(paginationMessage / 10));
      console.log(totalPages);
      // Actualiza los datos y el mensaje de respuesta
      setClientsData(result.data || []);
      setResponseMessage(result.message || 'Datos recibidos correctamente.');
   
      // Ahora accedemos a destination_response.data para obtener los datos de clientes
      if (result?.data) {
        setClientsData(result.data);
      } else {
        setClientsData([]); // Si no hay datos o el formato no es el esperado
        console.log('No se encontraron datos de clientes o el formato no es el esperado');
      }
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
      setResponseMessage('Error al hacer la solicitud.');
    }
   
   
   
    
  };
  
  return (
   
    <div className="App"  class="box">
     
       {/* Navbar de Bulma */}
       <Header
        currentPage={1}
        totalPages={1}
        onPageChange={1}
      />




     

      <h1 style={styles.heading}>Formulario de Búsqueda de Usuarios</h1>
      <form onSubmit={handleGetSubmit}  class="box">
        <div style={styles.inputGroup}>
          <label style={styles.label}>Filtro:</label>
          <input
          class="box"
            type="text"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Parámetro:</label>
          <input
          class="box"
            type="text"
            value={param}
            onChange={(e) => setParam(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Valor:</label>
          <input
          class="box"
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={styles.input}
          />
        </div>
       
       
        <button type="submit"  class="button is-rounded box">Buscar</button>
      </form>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      {clientsData.length > 0 && (
        <table  class="table">
          <thead>
            <tr>
            <th >Lock/Active</th>
              <th >Alias</th>
              <th >ID</th>
              <th >Nombre</th>
              <th >Apellido</th>
              <th >Email</th>
              <th >Contacto</th>
              <th >Actions</th>
            </tr>
          </thead>
          <tbody>
  {clientsData.map((client) => (
   registersqty=registersqty+1,
    <tr key={client.userId}>
                <td >{client.info.status=== true ? <i class="tag is-rounded is-medium is-success fas" title='DETAILS'></i>:<i class="tag is-rounded is-medium is-danger fas" title='DETAILS'></i>}  {client.info.isActive=== true ? <i class="tag is-rounded is-medium is-success fas" title='DETAILS'></i>:<i class="tag is-rounded is-medium is-warning fas" title='DETAILS'></i>}</td>

                <td >{client.alias}</td>
                <td >{client.userId}</td>
                <td ><input class="input is-rounded" value={client.name}></input></td>
                <td ><input class="input is-rounded" value={client.lastName}></input></td>
                
                <td ><input class="input is-rounded" value={client.email}></input></td>
                <td ><input class="input is-rounded" value={client.contact}></input></td>
                <td ><button class="button is-rounded fas fa-eye" title='DETAILS' onClick={() => openMOdal({name:client.name,lastName:client.lastName,mail:client.email,contact:client.contact}, client.info)}></button><i class="button is-rounded fas fa-trash" title='ERASE'></i></td>
   
    </tr>
    
  ))}
</tbody>
        </table>
      )}
       <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      
    </div>
    
  );
}

const openMOdal= (basicData,data)=>{
  Swal.fire({
    title: "<strong>HTML <u>example</u></strong>",
    icon: "info",
    html: `
    <label class="tag">Name</label>
    <input class="input is-rounded" value=${basicData.name}></input>
     <label class="tag">Last Name</label>
    <input class="input is-rounded" value=${basicData.lastName}></input>
     <label class="tag">E-Mail</label>
    <input class="input is-rounded" value=${basicData.mail}></input>
     <label class="tag">Contact</label>
       <input class="input is-rounded" value=${basicData.contact}></input>
        <label class="tag">Rol</label>
       <input class="input is-rounded" value=${data.rol.type}></input>
      ${data.isActive}
    `,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: `
      <i class="fa fa-thumbs-up"></i> Great!
    `,
    confirmButtonAriaLabel: "Thumbs up, great!",
    cancelButtonText: `
      <i class="fa fa-thumbs-down"></i>
    `,
    cancelButtonAriaLabel: "Thumbs down"
  });
}
const styles = {
  app: {
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    backgroundColor: '#f4f7fc',
    color: '#333',
  },
  heading: {
    color: '#3f51b5',
    marginBottom: '20px',
    fontSize: '24px',
    textAlign: 'center',
  },
  form: {
    maxWidth: '600px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    fontSize: '16px',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    boxSizing: 'border-box',
  },
  button: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
  },
  responseMessage: {
    textAlign: 'center',
    color: '#4caf50',
    fontSize: '18px',
    marginTop: '20px',
  },
  table: {
    marginTop: '30px',
    width: '100%',
    borderCollapse: 'collapse',
    backgroundColor: '#fff',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  },
  tableHeader: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    padding: '10px',
    textAlign: 'left',
  },
  tableData: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
  }
};

export default Users;
